import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_custom_assessment_response_completes_path,
  api_v1_custom_assessment_response_path,
  api_v1_custom_assessment_response_uncompletes_path,
  api_v1_custom_assessment_schema_question_path,
  api_v1_custom_assessment_schema_section_custom_assessment_schema_questions_path,
  api_v1_custom_assessment_schema_section_path,
  api_v1_custom_assessment_schema_version_custom_assessment_schema_sections_path,
  api_v1_custom_assessment_schema_version_path,
} from "~/routes"

const customAssessments = {
  questions: {
    post: function (schemaQuestionId, schemaQuestionData) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_section_custom_assessment_schema_questions_path(schemaQuestionId),
        type: "post",
        data: { schemaQuestion: schemaQuestionData },
        jsonData: true,
      })
    },
    patch: function (schemaQuestionId, schemaQuestionData) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_question_path(schemaQuestionId),
        type: "patch",
        data: {
          schemaQuestion: schemaQuestionData,
        },
        keyTransformOverrides: { _destroy: "_destroy" },
        jsonData: true,
      })
    },
    delete: function (schemaQuestionId) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_question_path(schemaQuestionId),
        type: "delete",
      })
    },
  },
  responses: {
    patch: function (responseId, { questionResponseValues }) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_response_path(responseId),
        type: "patch",
        jsonData: true,
        data: {
          response: {
            questionResponsesAttributes: Object.entries(questionResponseValues).map(([id, value]) => ({
              id,
              value,
            })),
          },
        },
      })
    },
  },
  schemaSections: {
    post: function (schemaVersionId, schemaSectionData) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_version_custom_assessment_schema_sections_path(schemaVersionId),
        type: "post",
        data: {
          schemaSection: schemaSectionData,
        },
      })
    },
    patch: function (schemaSectionId, schemaSectionData) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_section_path(schemaSectionId),
        type: "patch",
        data: {
          schemaSection: schemaSectionData,
        },
        jsonData: true,
      })
    },
    delete: function (schemaSectionId) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_section_path(schemaSectionId),
        type: "delete",
      })
    },
  },
  schemaVersions: {
    patch: function (schemaVersionId, schemaVersionData) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_schema_version_path(schemaVersionId),
        type: "patch",
        data: {
          schemaVersion: schemaVersionData,
        },
        jsonData: true,
      })
    },
  },
  responseCompletes: {
    patch: function (responseId, { questionResponseValues, ...otherValues }) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_response_completes_path(responseId),
        type: "post",
        jsonData: true,
        data: {
          response: {
            questionResponsesAttributes: Object.entries(questionResponseValues).map(([id, value]) => ({
              id,
              value,
            })),
            ...otherValues,
          },
        },
      })
    },
  },
  responseUncompletes: {
    post: function (responseId) {
      return submitAsyncRequest({
        url: api_v1_custom_assessment_response_uncompletes_path(responseId),
        type: "post",
      })
    },
  },
}

export default customAssessments
